import React from "react";
import { cn } from "../lib/helpers";
import Container from "./container";

import * as styles from "../styles/components/about-numbers.module.css";

function Numbers({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        <div className="grid justify-xxl-between">
          {items.map(({ _key, label, number }) => {
            return (
              <div className={cn("col-12 col-md-6 col-xl-4 col-xxl-auto", styles.col)} key={_key}>
                {label && (<div className={styles.label}>{label}</div>)}
                {number && (<div className={cn("h2", styles.number)}>{number}</div>)}
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default Numbers;
