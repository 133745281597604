import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/page-header";
import Numbers from "../components/about-numbers";
import AboutMain from "../components/about-main";
import LatestPosts from "../components/posts-latest";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutPageQuery {
    about: sanityAbout {
      title
      themePurple
      subtitle
      _rawDescription
      numbers {
        _key
        label
        number
      }
      flexibleContent {
        ... on SanityFcFeaturedBlocks {
          _key
          _type
          featuredBlocks {
            _key
            title
            _rawContent
            link {
              blank
              text
              url
            }
            image {
              ...SanityImage
              alt
            }
            switchOrder
          }
        }
        ... on SanityFcTeam {
          _key
          _type
          title
          _rawDescription
          members {
            _key
            photo {
              ...SanityImage
              alt
            }
            name
            position
            _rawBio
          }
        }
      }
      latestPosts {
        ... on SanityLibraryReference {
          _key
          _type
          library {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            types {
              _key
              libraryType {
                title
                slug {
                  current
                }
              }
            }
          }
        }
        ... on SanityNewsReference {
          _key
          _type
          news {
            title
            slug {
              current
            }
            publicationDate
            thumbnail {
              ...SanityImage
              alt
            }
          }
        }
      }
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    news: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const AboutUsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const about = (data || {}).about;
  const news = (data || {}).news.edges;

  const title = about.seo?.title || about.subtitle;
  const description = about.seo?.description || toPlainText(about._rawDescription);
  const image = about.seo?.thumbnail;
  const autoSiteTitle = !about.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={about.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {about.title && (<PageHeader title={about.title} subtitle={about.subtitle} description={about._rawDescription} />)}
      {about.numbers && about.numbers.length > 0 && (<Numbers items={about.numbers} />)}
      {about.flexibleContent && (<AboutMain content={about.flexibleContent} />)}
      {(news || about.latestPosts) && (<LatestPosts dPosts={news} mPosts={about.latestPosts} />)}
      {about.callToAction && (<CallToAction {...about.callToAction} />)}
    </Layout>
  );
};

export default AboutUsPage;
