import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/section-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    subtitle,
    description
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {subtitle && (<div className={cn("h3", styles.subtitle)}>{subtitle}</div>)}
        {title && (<h2 className={styles.title}>{title}</h2>)}
        {description && (<div className={cn("entry-content", styles.description)}><PortableText blocks={description} /></div>)}
      </Container>
    </div>
  );
}

export default PageHeader;
